import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-catalyst-core/src/components/layout.js";
import { Label, Input, Textarea, Button } from "theme-ui";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SEO = makeShortcode("SEO");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Objednávka" description="@todo" mdxType="SEO" />
    <h1 {...{
      "id": "objednávka"
    }}>{`Objednávka`}</h1>
    <p>{`Tato sekce se připravuje, velmi brzy si zde budete moci parametrizovat objednávku dle vašeho přádní.`}</p>
    <p>{`Do té doby nás prosím kontaktujte (stačí na sebe nechat telefon), rádi Vám provedeme kalkulaci na míru.`}</p>
    <p>{`Případně také `}<strong parentName="p">{`můžete využít kalkulátor objednávky`}</strong>{` na našich starých stránkách na adrese `}<a href="https://rak-obaly.cz/kalkulace-a-objednavka-obalu">{`rak-obaly.cz/kalkulace-a-objednavka-obalu`}</a>{`.`}</p>
    <hr></hr>
    <form name="objednavka" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
      <input type="hidden" name="form-name" value="objednavka" />
      <Label htmlFor='name' mdxType="Label">Vaše jméno: </Label>
      <Input type="text" name="name" mb={3} mdxType="Input" />
      <Label htmlFor="email" mdxType="Label">Vaše e-mail adresa:</Label>
      <Input type="email" name="email" mb={3} mdxType="Input" />
      <Label htmlFor="telefon" mdxType="Label">Váš telefon: </Label>
      <Input type="telefon" name="telefon" mb={3} mdxType="Input" />
      <Label htmlFor="zprava" mdxType="Label">Zpráva pro nás nebo upřesnění objednávky: </Label>
      <Textarea name="zprava" mb={3} mdxType="Textarea"></Textarea>
      <Button type="submit" mdxType="Button">Odeslat</Button>
    </form>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      